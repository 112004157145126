<i18n>
{
  "en": {
    "title": "Page of omnichannel messages from wepipe",
    "entity": "Messages",
    "soon": "Soon"
  },
  "pt-BR": {
    "title": "Página de conversas omnichannel da wepipe",
    "entity": "Conversas",
    "soon": "Em breve"
  }
}
</i18n>

<template>
  <div id="comments">
    <div class="content">
      <div class="we-title">
        {{ $t("title") }}
      </div>
      <div class="feature">
        <div class="icon">
          <font-awesome-icon icon="comments" />
        </div>
        {{ $t("entity") }}
      </div>
      <div class="soon">
        {{ $t("soon") }}
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss">
#comments {
  display: flex;
  justify-content: center;
  overflow-y: auto;
  padding: 20px;
  width: 100%;
  height: 100%;
  .content {
    margin: auto 0;
    max-width: 450px;
    .we-title {
      font-size: 30px;
      line-height: 34px;
      color: var(--text-1);
      font-weight: $medium;
      margin-bottom: 26px;
      text-align: center;
      b {
        font-weight: $semibold;
      }
    }
    .soon {
      margin: 0 auto;
      padding: 0 20px;
      text-transform: uppercase;
      font-size: 18px;
      font-weight: $medium;
      background: var(--orange);
      border-radius: 5px;
      color: var(--white);
      text-transform: uppercase;
      width: fit-content;
    }
    .feature {
      text-transform: uppercase;
      font-size: 26px;
      font-weight: $medium;
      color: var(--text-2);
      margin: 0 auto;
      display: flex;
      align-items: center;
      width: fit-content;
      margin-bottom: 26px;
      .icon {
        margin-right: 14px;
        svg {
          color: var(--text-2);
          height: 24px;
          width: auto;
        }
      }
    }
  }
}
</style>
